import {Backdrop, LinearProgress} from "@mui/material";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';


export default function Loader({message}) {
    return (
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card sx={{minWidth: 275}} displaycontent="center">
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        {message}
                    </Typography>
                    <LinearProgress color="inherit"/>
                </CardContent>
            </Card>
        </Backdrop>
    );
}
