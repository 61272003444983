import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { AccountBalanceWallet } from '@mui/icons-material';
import Constants from '../utils/Constants';

export default function NavBar({ address, network, selectedWallet, showWalletsDialog }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="image" component="div" sx={{ flexGrow: 1 }}>
                        <img className={"logo"} src={"./BlockchainCommoditiesLogo.svg"} alt={'Blockchain Commodities'} />
                    </Typography>
                    <Typography>
                        {(!selectedWallet || address === Constants.NOT_AVAILABLE) && <Button variant="contained" startIcon={<AccountBalanceWallet />}  onClick={() => showWalletsDialog(true)}>
                            Connect Wallet
                        </Button>}
                        {address !== Constants.NOT_AVAILABLE && selectedWallet === Constants.META_MASK_WALLET && <Button variant="contained" onClick={() => showWalletsDialog(true)}>
                            <img className={"nav-bar-wallet-icon"} src={require("../assets/MetaMask.png")} alt={'Metamask'} title={'Click to change wallet'} />
                        </Button>}
                        {address !== Constants.NOT_AVAILABLE && selectedWallet === Constants.TRON_LINK_WALLET && <Button variant="contained" onClick={() => showWalletsDialog(true)}>
                            <img className={"nav-bar-wallet-icon"} src={require("../assets/TronLink.png")} alt={'Tronlink'} title={'Click to change wallet'} />
                        </Button>}
                    </Typography>
                    <Typography>
                        Connected Account: {address}
                        <br />
                        Network: {network}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
