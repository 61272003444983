const Utility = {

    /**
     * Method to convert camel case to title case 
     * e.g. camelCase to Camel Case
     * @param {*} text 
     * @returns 
     */
    toTitleCase: function (text) {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    },

    /**
     * Method to make 
     * @param {*} ms 
     * @returns 
     */
    sleep: async function (ms) { return new Promise(r => setTimeout(r, ms)) },

}

export default Utility;
