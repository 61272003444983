import Utility from "../../utils/Utility";

/**
 * TronLink wallet service methods
 * @author Vijay Pratap Singh
 */
const TronLinkWalletService = {

    /**
     * Method to get wallet connection
     * @returns tronLinknstance
     */
    getWalletConnection: async function () {
        if (window.tronLink) {
            console.log("Getting tron link wallet connection")
            const accounts = await window.tronLink.request({ method: 'tron_requestAccounts' });
            console.log('accounts: ', accounts)
            return accounts ? true : false; 
        }
    },

    /**
     * Method to check if wallet is available or installed
     * @returns boolean
     */
    isWalletAvailable: function () {
        return window.tronLink ? true : false;
    },

    /**
     * Method to check if wallet is connected
     * @returns boolean
     */
    isWalletConnected: async function () {
        if (window.tronLink && window.tronLink.isReady) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * Method to get current network
     * @returns network
     */
    getNetwork: async function () {
        let node = window.tronWeb.fullNode.host !== undefined
            ? window.tronWeb.fullNode.host
            : window.tronWeb.eventServer.host;
        let network;
        if (node.includes('shasta')) {
            network = 'TRON Shasta Testnet';
        } else if (node.includes('nile')) {
            network = 'TRON Nile Testnet';
        } else if (node.includes('trongrid')) {
            network = 'TRON Mainnet (TronGrid)';
        } else if (node.includes('tronstack')) {
            network = 'TRON Mainnet (TronStack)';
        }
        return network;
    },

    /**
     * Method to get chain id from network
     * @returns chainId
     */
    getChainId: async function () {
        let node = window.tronWeb.fullNode.host !== undefined
            ? window.tronWeb.fullNode.host
            : window.tronWeb.eventServer.host;
        let chainId;
        if (node.includes('shasta')) {
            chainId = parseInt('94a9059e', 16);
        } else if (node.includes('nile')) {
            chainId = parseInt('cd8690dc', 16);
        } else if (node.includes('trongrid')) {
            chainId = parseInt('2b6653dc', 16);
        } else if (node.includes('tronstack')) {
            chainId = parseInt('2b6653dc', 16);
        }
        return chainId;
    },

    /**
     * Method to get wallet address
     * @returns base58_address
     */
    getWalletAddress: function () {
        return window.tronWeb.defaultAddress.base58;
    },

    /**
     * Method to deploy contract
     * @param {*} window 
     * @param {*} abi 
     * @param {*} bytecode 
     * @param {*} parameters 
     * @returns contractInstance
     */
    deployContract: async function (abi, bytecode, parameters) {
        let transaction = await window.tronWeb.transactionBuilder.createSmartContract({
            abi: abi,
            bytecode: bytecode,
            parameters: parameters,
            feeLimit: 15000000000, // 15000 TRX
        }, window.tronWeb.defaultAddress.hex);
        const signedTransaction = await window.tronWeb.trx.sign(transaction);
        const contractInstance = await window.tronWeb.trx.sendRawTransaction(signedTransaction);
        return contractInstance;
    },

    /**
     * Method to get transaction status
     * @param {*} transactionId 
     * @returns transaction_status
     */
    waitForContractDeployment: async function (contractInstance) {

        // get transaction info
        let transactionInfo = await window.tronWeb.trx.getTransactionInfo(contractInstance.txid);
        console.log("transactionInfo: ", transactionInfo)
        // if transaction info is available and transaction info has data, that means transaction is commited
        if (transactionInfo && transactionInfo.receipt) {
            return contractInstance.txid
        } else {
            do {
                await Utility.sleep(10000);
                transactionInfo = await window.tronWeb.trx.getTransactionInfo(contractInstance.txid);
                console.log("transactionInfo while waiting: ", transactionInfo)
            } while (!transactionInfo?.receipt)
            return contractInstance.txid
        }

    },

    /**
     * Method to get deployed contract details using transaction id
     * @param {*} transactionId 
     * @returns contractDetails
     */
    getDeployedContractDetails: async function (transactionId) {
        const transactionInfo = await window.tronWeb.trx.getTransactionInfo(transactionId);
        const transaction = await window.tronWeb.trx.getTransaction(transactionId);
        console.log('transactionInfo: ', transactionInfo);
        console.log('transaction: ', transaction);
        const network = await this.getNetwork();
        const chainId = await this.getChainId();
        return {
            network: network,
            chainId: chainId,
            contractName: transaction.raw_data.contract[0].parameter.value.new_contract.name,
            contractAddress: window.tronWeb.address.fromHex(transactionInfo.contract_address),
            transactionId: transaction.txID,
            blockNumber: transactionInfo.blockNumber,
            blockHash: transaction.raw_data.ref_block_hash,
            account: window.tronWeb.address.fromHex(transaction.raw_data.contract[0].parameter.value.owner_address),
            energyFee: transactionInfo.receipt.energy_fee,
            energyUsageTotal: transactionInfo.receipt.energy_usage_total,
            netFee: transactionInfo.receipt.net_fee
        }
    },

    /**
     * Method to subscribe to wallet event
     * @param {*} setAddress 
     * @param {*} setNetwork 
     * @param {*} resetWalletAndServices 
     * @returns 
     */
    subscribeToWalletEvents: function (validateAndConnectWallet, resetWalletAndServices) {
        return function (event) {
            // if there is an update in account, update the address
            if (event.data.message && event.data.message.action === "accountsChanged") {
                console.log('Tronlink accountsChanged event', event.data.message.data.address);
                validateAndConnectWallet();
            }
            // if there is an update in network, get the updated network
            if (event.data.message && event.data.message.action === "setNode") {
                console.log('got setNode event', event.data);
                validateAndConnectWallet();
            }
            // if wallet is disconnected, reset all wallet and services
            if (event.data.message && event.data.message.action === "disconnect") {
                // handler logic
                console.log('got wallet disconnect event', event.data);
                resetWalletAndServices();
            }
        }
    }

}

export default TronLinkWalletService;
