import TextField from "@mui/material/TextField";

export default function LibrariesInputs({librariesSpace, inputsValues, setInputsValues, errors, setErrors}) {

    function handleChange(index, event, input) {
        let currentValues = [...inputsValues];
        currentValues[index] = event.target.value
        setInputsValues(currentValues);
        if(event.target.value === "") {
            setErrors(errors => ({...errors, [input]: `Library address of ${input} is required`}));
        } else {
            setErrors(errors => ({...errors, [input]: undefined}));
        }
    }

    return (
        <div>
            {librariesSpace && librariesSpace.map((input, index) => (
                <TextField
                    key={index}
                    margin="normal"
                    required
                    fullWidth
                    id={input}
                    label={"Library address of " + input}
                    name={input}
                    type="text"
                    onChange={event => handleChange(index, event, input)}
                    error={Boolean(errors?.[input])}
                    helperText={errors?.[input]}
                />
            ))}
        </div>
    )
}