import { ContentCopy, Download } from "@mui/icons-material";
import { CardActions, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Utility from "../../utils/Utility";

export default function ShowContractDetails({
    contractDetails
}) {

    const downloadContractDetails = () => {
        try {
            const element = document.createElement("a");
            const file = new Blob([convertContentToText()], {
                type: "text/plain"
            });
            element.href = URL.createObjectURL(file);
            element.download = `contract-details-${contractDetails.contractName}-${contractDetails.network}-${contractDetails.chainId}.txt`;
            document.body.appendChild(element);
            element.click();
            element.remove();
        } catch (e) {
            console.error("Error while downloading contract details");
        }
    }

    const copyContractDetailsToClipBoard = async () => {
        await navigator.clipboard.writeText(convertContentToText());
    }

    const convertContentToText = () => {
        let textDetails = "";
        textDetails = Object.keys(contractDetails).map((key) => (
            textDetails + "\n" + Utility.toTitleCase(key) + " : " + contractDetails[key].toString()
        ));
        return textDetails;
    }

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
        </Box>
    );

    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    DO{bull}NOT{bull}FORGET{bull}TO{bull}COPY{bull}OR{bull}DOWNLOAD
                </Typography>
                <Typography variant="h5" component="div">
                    Contract Details
                </Typography>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    CONTRACT FIELDS
                                </TableCell>
                                <TableCell align="left"> VALUES</TableCell>
                            </TableRow>
                            {contractDetails && Object.keys(contractDetails).map((key, index) => (
                                <TableRow key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {Utility.toTitleCase(key)}
                                    </TableCell>
                                    <TableCell align="left"> {contractDetails[key]?.toString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions>
                <Button variant="contained" startIcon={<ContentCopy />} onClick={copyContractDetailsToClipBoard}>
                    Copy Deployment Details
                </Button>
                <Button variant="contained" startIcon={<Download />} onClick={downloadContractDetails}>
                    Download Deployment Details
                </Button>
            </CardActions>
        </Card>
    );
}