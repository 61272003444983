import chainData from '../../configs/chains.json';
import {ethers} from "ethers";

/**
 * MetaMask wallet service methods
 * @author Vijay Pratap Singh
 */
const MetaMaskWalletService = {

    /**
     * Method to get wallet connection
     * @returns boolean
     */
    getWalletConnection: async function () {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
            console.log('accounts:', accounts);
            return true;
        }
    },

    /**
     * Method to check if wallet is available or installed
     * @returns boolean
     */
    isWalletAvailable: function () {
        if (typeof window.web3 === 'undefined') return false;
        if (window.ethereum && window.ethereum.isMetaMask) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * Method to check if wallet is connected
     * @returns boolean
     */
    isWalletConnected: async function () {
        const [accounts, error] = await window.ethereum.request({method: 'eth_accounts'});
        console.log('accounts', accounts);
        if (!accounts || error) {
            return false;
        } else {
            return true;
        }
    },

    /**
     * Method to get current network
     * @returns network
     */
    getNetwork: async function () {
        const chainIdHex = await window.ethereum.request({method: 'eth_chainId'});
        const chainId = parseInt(chainIdHex, 16)
        const result = chainData.filter(chainObject => chainObject.chainId === chainId);
        if (result.length === 0) {
            return chainIdHex;
        } else {
            return result[0].name;
        }
    },

    /**
     * Method to get current chain id
     * @returns chainId
     */
    getChainId: async function () {
        const chainIdHex = await window.ethereum.request({method: 'eth_chainId'});
        return parseInt(chainIdHex, 16)
    },

    /**
     * Method to get wallet address
     * @returns base58_address
     */
    getWalletAddress: function () {
        return window.ethereum.selectedAddress;
    },

    /**
     * Method to deploy contract
     * @param {*} window
     * @param {*} abi
     * @param {*} bytecode
     * @param {*} parameters
     * @returns contractInstance
     */
    deployContract: async function (abi, bytecode, parameters) {
        console.log("Getting eth provider")
        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
        console.log("Getting Signer")
        const signer = ethProvider.getSigner();
        console.log("Getting Contract Factory")
        const factory = new ethers.ContractFactory(abi, bytecode, signer)
        console.log("Deploying Contract using Factory")
        const contractInstance = await factory.deploy(...parameters)
        console.log("Returning contract instance")
        return contractInstance;
    },

    /**
     * Method to wait till contract deployment is confirmed
     * @param {*} contractInstance
     * @returns transaction_id
     */
    waitForContractDeployment: async function (contractInstance) {
        const deployedResult = await contractInstance.deployed();
        return deployedResult.deployTransaction.hash;
    },

    /**
     * Method to get deployed contract details using transaction id
     * @param {*} transactionId
     * @returns Promise
     */
    getDeployedContractDetails: async function (transactionId) {
        var promise = new Promise((resolve, reject) => {
            console.log("Inside Promise")
            try {
                const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
                ethProvider.once(transactionId, async (transaction) => {
                    resolve({
                        network: await this.getNetwork(),
                        chainId: await this.getChainId(),
                        contractName: undefined,
                        contractAddress: transaction.contractAddress,
                        transactionId: transaction.transactionHash,
                        blockNumber: transaction.blockNumber,
                        blockHash: transaction.blockHash,
                        account: transaction.from ? transaction.from : '',
                        gasUsed: transaction.gasUsed ? transaction.gasUsed : '',
                        cumulativeGasUsed: transaction.cumulativeGasUsed ? transaction.cumulativeGasUsed : '',
                        effectiveGasPrice: transaction.effectiveGasPrice ? transaction.effectiveGasPrice : ''
                    })
                });
            } catch (e) {
                console.log('Error occurred while getting deployed contract details', e);
                reject(e)
            }

        });
        return promise;
    },

    /**
     * Method to subscribe to wallet event
     * @param {*} validateAndConnectWallet
     * @param {*} resetWalletAndServices
     * @returns
     */
    subscribeToWalletEvents: function (validateAndConnectWallet, resetWalletAndServices) {
        window.ethereum.on('accountsChanged', (accounts) => {
            console.log("Account changed on metamask", accounts)
            validateAndConnectWallet();
        });
        window.ethereum.on('chainChanged', (chainId) => {
            console.log("Network changed on metamask", chainId)
            validateAndConnectWallet();
        });
        window.ethereum.on('disconnect', () => {
            console.log("Account disconnected on metamask")
            resetWalletAndServices();
        });
    }

}

export default MetaMaskWalletService;
