import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Constants from "../../utils/Constants";


/**
 * Component function to display confirmation dialog
 * @param props
 * @returns
 */
export default function WalletsDialog({
    handleDisagree,
    handleWalletSelect,
}) {


    return (
        <Dialog

            open={true}
            onClose={() => handleDisagree()}
            aria-labelledby="wallet-dialog-title"
        >
            <DialogTitle id="wallets-dialog-title">Select your wallet</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', gap: '2px 15px' }}>
                    <div onClick={() => handleWalletSelect(Constants.META_MASK_WALLET)} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  alignItems: 'center' }}>
                        <img className={"wallet-icon"} src={require("../../assets/MetaMask.png")} alt={'Metamask'} />
                        <p>MetaMask</p>
                    </div>
                    <div onClick={() => handleWalletSelect(Constants.TRON_LINK_WALLET)} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  alignItems: 'center' }}>
                        <img className={"wallet-icon"} src={require("../../assets/TronLink.png")} alt={'TronLink'}/>
                        <p>TronLink</p>
                    </div>
                </div>
            </DialogContent>

        </Dialog>
    );
}
