import TextField from "@mui/material/TextField";

export default function SmartContractInputs({inputs, inputsValues, setInputsValues, errors, setErrors}) {

    function handleChange(index, event, input) {
        let currentValues = [...inputsValues];
        currentValues[index] = event.target.value
        setInputsValues(currentValues);
        if(event.target.value === "") {
            setErrors(errors => ({...errors, [input.name]: `${input.name} is required`}));
        } else {
            setErrors(errors => ({...errors, [input.name]: undefined}));
        }
    }

    return (
        <div>
            {inputs && inputs.map((input, index) => (
                <TextField
                    key={index}
                    margin="normal"
                    required
                    fullWidth
                    id={input.name}
                    label={input.name}
                    name={input.name}
                    type="text"
                    onChange={event => handleChange(index, event, input)}
                    error={Boolean(errors?.[input.name])}
                    helperText={errors?.[input.name]}
                />
            ))}
        </div>
    )
}