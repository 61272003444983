import './App.css';
import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import Container from "@mui/material/Container";
import Loader from "./components/common/Loader";
import Toast from "./components/common/Toast";
import NavBar from "./components/Navbar";
import UploadContract from "./components/contract-utils/UploadContract";
import { createTheme, ThemeProvider } from "@mui/material";
import ConfirmationDialog from "./components/common/ConfirmationDialog";
import WalletsDialog from "./components/common/WalletsDialog";
import TronLinkWalletService from './services/wallets/TronLinkWalletService';
import MetaMaskWalletService from './services/wallets/MetaMaskWalletService';
import Utility from './utils/Utility';
import Constants from './utils/Constants';


function App() {

    const { ethereum } = useMetaMask();
    const [loader, showLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [toast, showToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("info");
    const [installWalletDialog, showInstallWalletDialog] = useState(false);
    const [connectWalletsDialog, showWalletsDialog] = useState(false);
    const [address, setAddress] = useState(Constants.NOT_AVAILABLE);
    const [network, setNetwork] = useState(Constants.NOT_AVAILABLE);
    const [selectedWallet, setSelectedWallet] = useState(null)
    const [walletService, setWalletService] = useState(null)

    useEffect(() => {

        const enableEthereum = () => {
            try {
                ethereum.enable();
            } catch (error) {
                console.warn('Unable to enable ethereum');
            }
        }

        const validateAndConnectWallet = async () => {
            console.log("Validating wallet and connection...");
            if (!walletService.isWalletAvailable()) {
                console.log("Wallet is not available")
                showInstallWalletDialog(true);
            } else {
                console.log("Wallet is available, connecting...");
                setLoaderMessage(`Connecting ${Utility.toTitleCase(selectedWallet)}`)
                showLoader(true);
                const isWalletConnected = await walletService.isWalletConnected();
                console.log(`Is wallet connected: ${isWalletConnected}`);
                if (!isWalletConnected) {
                    console.log("Wallet is not connected, connecting wallet");
                    const walletConnectionResult = await walletService.getWalletConnection();
                    console.log('walletConnectionResult:', walletConnectionResult)
                    if (!walletConnectionResult) {
                        resetWalletAndServices();
                        showLoader(false);
                        setToastMessage("Unable to establish connection with your wallet. Please check your wallet");
                        setToastSeverity("error");
                        showToast(true);
                        return;
                    }
                }
                const network = await walletService.getNetwork()
                setNetwork(network);
                setAddress(walletService.getWalletAddress());
                showLoader(false);
            }
            showWalletsDialog(false);
        }

        if (!selectedWallet) {
            showWalletsDialog(true);
        } if(!walletService) {
            handleWalletSelect(selectedWallet);
        } else {
            validateAndConnectWallet();
        }

        if (walletService && window.tronWeb && selectedWallet === Constants.TRON_LINK_WALLET) {
            window.addEventListener('message', walletService.subscribeToWalletEvents(validateAndConnectWallet, resetWalletAndServices));
            return () => window.removeEventListener('message', walletService.subscribeToWalletEvents(validateAndConnectWallet, resetWalletAndServices))
        }

        if (walletService && window.ethereum && selectedWallet === Constants.META_MASK_WALLET) {
            walletService.subscribeToWalletEvents(validateAndConnectWallet, resetWalletAndServices)
        }

        window.addEventListener('load', enableEthereum)
        return () => window.removeEventListener('load', enableEthereum);

    }, [selectedWallet, walletService, ethereum]);

    const resetWalletAndServices = () => {
        showWalletsDialog(true);
        setAddress(Constants.NOT_AVAILABLE);
        setNetwork(Constants.NOT_AVAILABLE);
    }

    /**
     * Handler for closing Toast
     * @param event
     * @param reason
     * @returns
     */
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        showToast(false);
    };

    const closeDialog = () => {
        showInstallWalletDialog(false);
        showWalletsDialog(false);
    }

    const handleWalletInstallAgree = () => {
        if (selectedWallet === Constants.META_MASK_WALLET)
            window.open(Constants.META_MASK_WALLET_DOWNLOAD_LINK);
        else if (selectedWallet === Constants.TRON_LINK_WALLET)
            window.open(Constants.TRON_LINK_WALLET_DOWNLOAD_LINK);
        installWalletDialog(false);
    }

    /**
     * Method to execute when user selects a wallet from popup
     * @param {*} wallet 
     */
    const handleWalletSelect = (wallet) => {
        showWalletsDialog(false);
        setSelectedWallet(wallet);
        if (wallet === Constants.META_MASK_WALLET) {
            setWalletService(MetaMaskWalletService);
        } else if (wallet === Constants.TRON_LINK_WALLET) {
            setWalletService(TronLinkWalletService);
        }
    }

    /**
     * Custom Theme
     * @type {Theme}
     */
    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#081437',
            }
        },
    });


    return (
        <ThemeProvider theme={customTheme}>
            <NavBar
                address={address}
                network={network}
                selectedWallet={selectedWallet}
                showWalletsDialog={showWalletsDialog}
            />
            <UploadContract
                selectedWallet={selectedWallet}
                walletService={walletService}
                showLoader={showLoader}
                setLoaderMessage={setLoaderMessage}
                showWalletsDialog={showWalletsDialog}
            />
            <Container component="main">
                {loader &&
                    <Loader
                        message={loaderMessage}
                    />
                }
                {toast && (
                    <Toast
                        message={toastMessage}
                        severity={toastSeverity}
                        handleToastClose={handleToastClose}
                    />
                )}
                {installWalletDialog && (
                    <ConfirmationDialog
                        agreeText="Install"
                        notAgreeText="Cancel"
                        title={Utility.toTitleCase(selectedWallet) + " not available"}
                        message={"This application could not find " + Utility.toTitleCase(selectedWallet) + ", Please install... "}
                        handleDisagree={closeDialog}
                        handleAgree={handleWalletInstallAgree}
                    />
                )}
                {connectWalletsDialog && (
                    <WalletsDialog
                        handleDisagree={closeDialog}
                        handleWalletSelect={handleWalletSelect}
                    />
                )}
            </Container>
        </ThemeProvider>

    );
}

export default App;
